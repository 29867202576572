import React from "react"
import Info2column from "../../../components/Info2column"
import QuestionAndAnswer2colmun from "../../../components/QuestionAndAnswer2colmun"
import CommentText from "../../../components/CommentText"
import ColumnGrid from "../../../components/ColumnGrid"
import CommonButton from "../../../components/CommonButton"
import ModalLink from "../../../components/ModalLink"
import LayoutInterview from "../../../components/LayoutInterview"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Seo from "../../../components/Seo"

import IconInfo from "../../../images/icon_info.svg"
import IconPhoto from "../../../images/icon_photo.svg"
import QuestionAndAnswerToggle from "../../../components/QuestionAndAnswerToggle"
import FlagVn from "../../../images/flag_vn.svg"
import FlagJp from "../../../images/flag_jp.svg"

import FaceImg01 from "../../../images/interview/02/face_01.jpg"
import FaceImg02 from "../../../images/interview/02/face_02.jpg"

import css from "../stylesheets/interview.module.scss"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewDetail}>
      <Seo title={`自分の心と体を大切にしながら働く | INTERVIEW`} />
      <LayoutInterview lang={`ja`}>
        <Info2column id={`interview-02`} noBorder={true}>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div>
            <p className={css.date}>{`2020.3.25`}</p>
            <h3 className={`mb20`}>自分の心と体を大切にしながら働く</h3>
            <p>
              斉藤健一
              <small>さん（39歳）</small>× トゥイー
              <small>さん（26歳）</small>
            </p>
            <p>
              トゥイーさんは
              <AnchorLink
                className={`linkUnderline`}
                to="/ja/basic-information/kaigo/#section-2"
              >
                EPA介護福祉士候補者
              </AnchorLink>
              として、長野県塩尻市にある特別養護老人ホーム『
              <ModalLink id={`facility`} className={`linkUnderline`}>
                <span style={{ color: "black" }}>
                  グレイスフル塩尻
                  <img src={IconPhoto} alt="" />
                </span>
                <div>
                  <h3>働いている施設</h3>
                  <Img fluid={data.facility1.childImageSharp.fluid} />
                  <Img fluid={data.facility2.childImageSharp.fluid} />
                  <Img fluid={data.facility3.childImageSharp.fluid} />
                  <Img fluid={data.facility4.childImageSharp.fluid} />
                </div>
              </ModalLink>
              』にベトナムからやってきました。日本に来てから2か月半の研修を終え、施設で働き始めて約2年が経ちました。
            </p>
            <p>
              介護長の斉藤健一さんは、トゥイーさんの働きを見てきて「介護のセンスがある」と話します。同じベトナム人介護者がたくさんいる環境での仕事について、それぞれの立場から話を聞きました。
            </p>
            <h3>PROFILE</h3>
            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>名前</th>
                  <td>グエン ゴック トゥイーさん</td>
                </tr>
                <tr>
                  <th>勤務先</th>
                  <td>
                    社会福祉法人サン・ビジョン
                    特別養護老人ホーム『グレイスフル塩尻』 介護職員
                  </td>
                </tr>
                <tr>
                  <th>出身地</th>
                  <td>
                    <img src={FlagVn} alt="ベトナム国旗" className={`flag`} />
                    ベトナム・ハノイ
                  </td>
                </tr>
                <tr>
                  <th>年齢</th>
                  <td>26歳（1993年生まれ） </td>
                </tr>
                <tr>
                  <th>経歴</th>
                  <td>
                    ハノイ医療短期大学
                    看護学科卒業後、総合病院の看護師として1年間働く
                  </td>
                </tr>
                <tr>
                  <th>
                    入国時の
                    <br />
                    在留資格
                  </th>
                  <td>EPA介護福祉士候補者</td>
                </tr>
                <tr>
                  <th>来日</th>
                  <td>2018年5月</td>
                </tr>
              </tbody>
            </table>

            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>名前</th>
                  <td>斉藤健一さん</td>
                </tr>
                <tr>
                  <th>勤務先</th>
                  <td>
                    社会福祉法人サン・ビジョン
                    特別養護老人ホーム『グレイスフル塩尻』 介護長
                  </td>
                </tr>
                <tr>
                  <th>出身地</th>
                  <td>
                    <img src={FlagJp} alt="日本国旗" className={`flag`} />
                    長野県諏訪市
                  </td>
                </tr>
                <tr>
                  <th>年齢</th>
                  <td>39歳（1981年生まれ）</td>
                </tr>
                <tr>
                  <th>経歴</th>
                  <td>桃山学院大学 社会学部 社会福祉学科卒</td>
                </tr>
                <tr>
                  <th>入社</th>
                  <td>2003年4月</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Info2column>
        <QuestionAndAnswerToggle
          question={`介護の仕事をやってみてどうですか？`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                やることがたくさんあるので大変ですが、慣れてきたら毎日楽しいです。
                <br />
                最初の半年間は、先輩と一緒に、少しずつ仕事をしました。利用者さんたちと毎日話して、気持ちや性格がわかるようになってきたら、介護もうまくできるようになってきました。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                トゥイーさんは、明るくてよく気がつきます。介護の技術を身につけるのに苦労するのは、日本人でも外国人でもみんな一緒。トゥイーさんは、利用者さん一人ひとりを大切にして、その場に合わせて自分で解決していきます。このあいだは、夕方に利用者さんのひげを電気シェーバーで剃っていましたよね。あれはなぜですか？
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                皮膚にトラブルがあって、薬を塗るのにひげがないほうがよかったんです。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                なるほど。一人ひとりについて「いまこうしたほうがいい」と気づけるのは、観察力があると思います。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                気づこうとしているのではなくて、目についちゃうんです。
                わたしにとって難しいのは、
                <ModalLink id={`modal-1`} style={{ display: `inline-block` }}>
                  <span>
                    個別ケア
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>個別ケア</h3>
                    <p>
                      集団生活になる施設の暮らしでも、一人ひとりのやりたいことや理想を大事にすることが、個別ケアです。必要な支援を行うための「個別援助計画」を立て、利用者の毎日の様子を観察して、状態にあわせた介護をします。
                    </p>
                  </div>
                </ModalLink>
                です。利用者さんは一人ひとり身体の状態も性格も違います。最初は、手助けしすぎて注意されることもありました。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                すべて手助けしてしまえば、わたしたちは楽かもしれませんね。でも実際には、利用者さんが自分でできそうなことが少しでもあれば、それをできるようにするのが介護です。そうやって、一つひとつ考えていくのが、介護のおもしろいところだと思います。
                <br />
                認知症の方は、さっきまでやっていたことが、急にできなくなることがあります。逆に、いままでうまくいかなかったのに、突然できるようになることもあります。これまでの経験、その場で考える力、仲間との相談など、やれることに終わりがないことが楽しい。それが大変さでもあるんですけどね。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg01} name={`トゥイー さん`}>
                <p>
                  そうですね。一人ひとりとお話しして、相手を知ることが大事だと思います。
                </p>
              </CommentText>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`斉藤 さん`}
              >
                <p>
                  そうなると、コミュニケーションが大事ですが、みんな方言に苦労していますよね。トゥイーさんもわからない言葉はありましたか？
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                利用者さんに「パジャマを着替えますか？」と聞いて「着替えん」と言われたことがあります。わたしには「着替える」と聞こえて、そのままパジャマを着替えるお手伝いをしようとしたら、「着替えんと言ったでしょ」と。先輩に相談したら、長野県の方言で「着替えない」を「着替えん」と言うことを知りました。
              </p>
              <p>
                わからないときは、わかるまで聞き返すようにしています。「もう一度お願いします」「紙に書いてください」「ほかの言葉で何と言いますか？」「わたしは外国人でまだ日本語がよくわからないので、教えてください」と言うと、みなさんゆっくり丁寧に教えてくれます。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`働いている施設の特徴は何ですか？ `}>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image5.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                「リフト」や「スライディングボード」など移乗をサポートする福祉用具を使っています。まだベトナムには福祉用具はあまりないので、ぜひこうした技術を持って帰りたいと思っています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image6.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                わたしたちは「ノーリフティングポリシー」を大事にしています。ノーリフティングポリシーとは、「抱える」「持ち上げる」といった動作を最小限にする考え方です。利用者さんの負担を減らすと同時に、介護者の負担も減らすことができます。そのために、福祉用具を取り入れています。
              </p>
              <p>
                また、介護者の
                <ModalLink id={`modal-2`} style={{ display: `inline-block` }}>
                  <span>
                    セルフケア
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>セルフケア</h3>
                    <p>
                      自分の健康を自分で管理することをセルフケアといいます。仕事の疲れやストレスと上手につきあうための方法を学んだり、運動、睡眠、仕事以外の生活の楽しみなどによって、心と体を整えます。
                    </p>
                  </div>
                </ModalLink>
                も大切にしています。サンフランシスコの介護施設に研修に行ったときには、精神科医によるセルフケアのワークショップに参加しました。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`一緒に働くために工夫していることはありますか？ `}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image7.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                わたしたちの施設には、ベトナム人のEPA介護福祉士候補者が14人働いています。彼らをサポートするために月に約20時間の日本語学習時間があり、月に4回は日本語教師が授業をしてくれています。2年目からは、月に1、2回、介護福祉士国家試験のための授業もあります。
                <br />
                2016年に初めてベトナム人を受け入れたときには、何をどうやってサポートしたらいいのか、わからないことだらけでした。でも、彼らと働いてわかってきたのは、困っていても迷惑をかけたくないと思って、我慢してしまうことがよくあるということでした。だから、「大丈夫です」と言われても質問の仕方を工夫したり、くり返し聞いたりしています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                斉藤さんはわからないことを教えてくれるだけじゃなくて、いつも「元気ですか？」と聞いてくれますね。「元気ですよ」と答えても、「本当？」って、何度も聞いてくれます。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                いまはベトナム人の先輩が後輩をフォローしてくれるので、わたしの出番はあまりありませんが、困ったら「困った」と言ってもらえるようにしたいですね。
                <br />
                2019年には、日本人スタッフと一緒にベトナムについての勉強会と食事会を企画しました。ベトナムと日本の違いを知れば、一緒に働きやすくなると考えたんです。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                勉強会では、日本人は相手がミスをしても「大丈夫」とフォローして指摘しないことがよくあると伝えました。それだと、わたしたちは間違いに気づかないまま、同じことをくり返してしまいます。だから「間違えたことがあったらすぐに教えてください」とお願いしました。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>文化の違いを知れたのはよかったですね。</p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>

            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                勉強会の後は、みんなちゃんと間違いを教えてくれるようになりました。「ゆっくり話してください」ともお願いしたら、コミュニケーションもとりやすくなりました。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`日本ではどんな生活をしていますか？`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image9.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img
                fluid={data.image10.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image11.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                会社が用意してくれたアパートに、同じ施設で働くベトナム人と3人で暮らしています。通勤時間は、自転車で約10分です。
                <br />
                食事は、3食すべて自分でつくっています。施設で食事を頼むこともできますが、わたしは毎日お弁当を持って行きます。ルームメイトもいつもは自分のぶんは自分でごはんをつくります。でも、2人が休みで1人が仕事の日は、休みの人たちがベトナム料理をつくって、みんなで食べます。料理上手な子がいて、わたしは片付け担当（笑）。
                <br />
                必要な食材はほとんどスーパーで買えます。ベトナムの調味料や日本ではあまり売っていない鴨の肉などは、ベトナムのオンラインショップでまとめて買っているので、不便さはありませんね。
                <br />
                休みの日は、長野県で一番栄えている松本に行って、ショッピングモールで買い物をしたり、松本城のまわりを散歩したりするのが好きです。電車にも慣れて、松本へは電車で約15分です。
                <br />
                一緒に働いているベトナム人の仲間がたくさんいるので、あまりさびしくはありません。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`これからの目標を教えてください。 `}>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                まずは、日本語能力試験でN1に合格することです。2年後には、介護福祉士国家試験に合格したいです。そのあとは、ベトナムに帰って日本の介護技術について教えたい。ハノイには、老人ホームは指で数えるくらいしかありません。ベトナムでも高齢者がこれから増えていくので、介護施設が必要になってくると思います。
                <br />
                いまわたしは、利用者のみなさんのことを、自分の家族のように感じています。ちょっと落ち込んでいると、利用者さんが励ましてくれることもあります。おしゃべりが楽しくて、疲れを忘れることもあるんですよ。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image12.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                外国人介護者を受け入れるようになって、3年経ちました。安心して働いてもらえるように、仕事も生活もサポートしていきたいと思っています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <div className={`mt30`}>
          <Info2column noBorder={true}>
            <div></div>
            <div>
              <p style={{ margin: 0 }}>働いている施設</p>
              <h3 className={`mb10`}>
                <ModalLink id={`facility`} className={`linkUnderline`}>
                  <span style={{ color: "black" }}>
                    グレイスフル塩尻
                    <img src={IconPhoto} alt="" />
                  </span>
                  <div>
                    <h3>働いている施設</h3>
                    <Img fluid={data.facility1.childImageSharp.fluid} />
                    <Img fluid={data.facility2.childImageSharp.fluid} />
                    <Img fluid={data.facility3.childImageSharp.fluid} />
                    <Img fluid={data.facility4.childImageSharp.fluid} />
                  </div>
                </ModalLink>
              </h3>
              <p className={`mb40`}>
                塩尻駅の目の前に立つ、13階建ての建物内に、特別養護老人ホームのほか、グループホーム、デイサービスセンター、認定こども園、一般向けの住宅などがあります。特別養護老人ホーム（3-9階）は、各階に約20名の利用者が暮らしていて、介護者は8人ずつ働いています。長野県、愛知県、岐阜県にも施設があり、2016年から70人以上のEPA介護福祉士候補者を受け入れています。
              </p>
              <p>
                <a
                  href={`https://sun-vision.or.jp`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  https://sun-vision.or.jp
                </a>
              </p>

              <div className="credit">
                <p>
                  Text by Tami Ono
                  <br />
                  Photo by Natsuki Kuroda
                </p>
              </div>

              <div className="mt40">
                <ColumnGrid>
                  <div className={css.commonButton}>
                    <CommonButton to={`/ja/interview/1`} align={`center`}>
                      PREV
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/ja/interview/`} align={`center`}>
                      BACK
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/ja/interview/3`} align={`center`}>
                      NEXT
                    </CommonButton>
                  </div>
                </ColumnGrid>
              </div>
            </div>
          </Info2column>
        </div>
      </LayoutInterview>
    </div>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "interview/02/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "interview/02/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "interview/02/03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(relativePath: { eq: "interview/02/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "interview/02/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "interview/02/06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image7: file(relativePath: { eq: "interview/02/07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(relativePath: { eq: "interview/02/08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(relativePath: { eq: "interview/02/09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image10: file(relativePath: { eq: "interview/02/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image11: file(relativePath: { eq: "interview/02/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image12: file(relativePath: { eq: "interview/02/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    facility1: file(relativePath: { eq: "interview/02/facility_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility2: file(relativePath: { eq: "interview/02/facility_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility3: file(relativePath: { eq: "interview/02/facility_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility4: file(relativePath: { eq: "interview/02/facility_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
